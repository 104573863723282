import React from 'react';
import { SettingsDropdownComponent } from '../SettingsDropdownComponent';
import { SettingsIconComponent } from '../SettingsIconComponent';

export const SettingsIconAndDropdownComponent = (
  { settings, changeHandler, iconClickHandler, blurHandler, showSettings }
) => (
  <div>
    <div className='settings-icon'>
      <div className='element-event-handler'>
        <input className='input-element' readOnly value='' onClick={iconClickHandler} onBlur={blurHandler} />
      </div>
      <div className='generic-text-with-svg'>
        <SettingsIconComponent />
      </div>
    </div>
    {showSettings
      ? <SettingsDropdownComponent settings={settings} changeHandler={changeHandler} />
      : <React.Fragment />}
  </div>
);
