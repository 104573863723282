import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { MantineProvider } from '@mantine/core';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { useStateHydration } from './hooks/UseStateHydration';
import { appStageAndStateToReduxStore } from './transformations/core/redux';

export const classToActionMiddleWare = () => (next) => (action) => next({ ...action });

export const WrappedApp = () => {
  const state = useStateHydration();
  const store = appStageAndStateToReduxStore(process.env.REACT_APP_STAGE, state);

  return (
    <Provider store={store}>
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <App />
      </MantineProvider>
    </Provider>
  );
};

ReactDOM.render(<WrappedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export default WrappedApp;
