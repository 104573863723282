import { ReduxAction } from '@helix/react-lib';

import { Core, HelixUser } from '../../data/datatypes';
import { stringsMatch } from '../../transformations/core';

export const INITIAL_CORE_STATE = new Core();
export const SIGN_IN = 'SIGN_IN';
export const SHOW_SETTINGS = 'SHOW_SETTINGS';
export const ROLES = 'ROLES';

export const signInPayloadToSignInAction = (signInPayLoad = {}) =>
  new ReduxAction(SIGN_IN, signInPayLoad);

export const rolesToSetHelixUserRolesReduxAction = (roles = []) =>
  new ReduxAction(ROLES, roles);

export const booleanToShowSettingsAction = (showSettings = false) =>
  new ReduxAction(SHOW_SETTINGS, showSettings);

export const setAuthenticated = (state = {}, signInPayload = {}) => ({
  ...state,
  ...signInPayload,
});

export const setShowSettings = (state = {}, showSettings = false) => ({
  ...state,
  showSettings,
});

const setHelixUserRoles = (
  {
    authenticated,
    helixUser: {
      givenName,
      familyName,
      email,
      userId,
      employeeId,
      teamMembers,
    } = new HelixUser(),
    showSettings,
  } = new Core(),
  roles = []
) => {
  return {
    authenticated,
    helixUser: new HelixUser(
      givenName,
      familyName,
      email,
      userId,
      employeeId,
      teamMembers,
      roles
    ),
    showSettings,
  };
};

export const coreReducer = (state = INITIAL_CORE_STATE, { type = '', payload }) => {
  const stringToBoolean = stringsMatch(type);
  if (stringToBoolean(SIGN_IN)) return setAuthenticated(state, payload);
  if (stringToBoolean(SHOW_SETTINGS)) return setShowSettings(state, payload);
  if (stringToBoolean(ROLES)) return setHelixUserRoles(state, payload);

  return state;
};

export default coreReducer;
