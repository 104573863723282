/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSettings = /* GraphQL */ `
  query GetSettings($employeeId: String!) {
    getSettings(employeeId: $employeeId) {
      employeeId
      settings
    }
  }
`;
export const getPayViewData = /* GraphQL */ `
  query GetPayViewData($employeeId: String!) {
    getPayViewData(employeeId: $employeeId) {
      employeeId
      employeeName
      employeePayPeriods
      teamMemberPayData
    }
  }
`;
export const getPayRules = /* GraphQL */ `
  query GetPayRules($startDate: String!) {
    getPayRules(startDate: $startDate) {
      value
    }
  }
`;
export const getRoles = /* GraphQL */ `
  query GetRoles($employeeId: String!) {
    getRoles(employeeId: $employeeId) {
      value
    }
  }
`;
export const getMetricDefinitions = /* GraphQL */ `
  query GetMetricDefinitions {
    getMetricDefinitions {
      name
      expression
      metricNames
    }
  }
`;
export const getUserPayPeriodDataRowsDetailed = /* GraphQL */ `
  query GetUserPayPeriodDataRowsDetailed(
    $startDate: String!
    $endDate: String!
    $employeeId: String!
  ) {
    getUserPayPeriodDataRowsDetailed(
      startDate: $startDate
      endDate: $endDate
      employeeId: $employeeId
    ) {
      value
    }
  }
`;
export const getUserPayPeriodDataRows = /* GraphQL */ `
  query GetUserPayPeriodDataRows($startDate: String!, $endDate: String!) {
    getUserPayPeriodDataRows(startDate: $startDate, endDate: $endDate) {
      value
    }
  }
`;
export const getClients = /* GraphQL */ `
  query GetClients {
    getClients {
      value
    }
  }
`;
