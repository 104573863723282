/* eslint-disable indent */
import { DEFAULT_COMPONENT, valueToIsEqual, arrayToHasLength } from '@helix/react-lib';
import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  HelixUser,
  LocalStorageItem,
  ReduxStateWithoutPrivateData,
  Setting,
} from '../../data/datatypes';

export const PERMANENT_ITEM_TTL = 0;
export const SETTINGS_API_PATH = '/settings';
export const DEFAULT_REACT_APP_STAGE = 'LOCAL';
export const PAY_PER_DEPENDENCY = '@helix/paydashboard-ui';
export const CARET = '^';
export const VERSION_PREFIX = 'v';
export const FORWARD_SLASH_ENCODED_DELIMETER = '%2F';

export const THEME_SETTING_NAME = 'theme';
export const DEFAULT_THEME_NAME = '';
export const DARK_THEME_NAME = 'dark';
export const DARK_THEME_CLASS_NAME = 'dark-mode';
export const DEFAULT_THEME_CLASS_NAME = '';

/*
  After signIn, retrieve AwsUser and transform to HelixUser for storage
  AwsUser               ->               HelixUser
  AwsUser -> Payload
             Payload[Identity] -> UserId
                      Payload, UserId -> HelixUser
*/

// awsUserToPayload :: AwsUser -> Payload
export const awsUserToPayload = ({
  signInUserSession: { idToken: { payload = {} } = {} } = {},
} = {}) => payload;

// identitiesToUserId :: [Identity] -> UserId
export const identitiesToUserId = (identities = []) => identities[0].userId;

// payloadToHelixUser :: Payload -> UserId -> HelixUser
export const payloadToHelixUser = ({
  given_name: givenName,
  family_name: familyName,
  email,
  'custom:employee_id': employeeId,
  identities = [],
}) =>
  new HelixUser(givenName, familyName, email, identitiesToUserId(identities), employeeId);

export const dataToRedirectUrl = (data = '') => {
  // the path to redirect to comes back with / encoded as %2F
  // ex. '/settings' -> '%2Fsettings'
  return `/${data.split(FORWARD_SLASH_ENCODED_DELIMETER)[1]}`;
};

// envToStage :: Object -> String;
export const envToStage = ({ REACT_APP_STAGE = '' }) =>
  REACT_APP_STAGE || DEFAULT_REACT_APP_STAGE;

// stateToAuthenticated :: ReduxState -> Boolean
export const stateToAuthenticated = ({ core: { authenticated = false } = {} } = {}) =>
  authenticated;

// locationToRedirectString :: Locationn -> String
export const locationToRedirectString = ({ pathname = '', search = '' } = {}) =>
  `/auth?redirect=${pathname}${search}`;

// valuesToRender :: Boolean, React.Component, String -> Function -> React.Component
export const valuesToRender =
  (isAuth = false, Component = DEFAULT_COMPONENT, redirectString = '') =>
  () =>
    isAuth ? <Component /> : <Redirect to={redirectString} />;

const booleanToRenderComponent = (boolean, trueComponent, falseComponent) =>
  boolean ? trueComponent : falseComponent;

export const valuesToValuesToRender =
  (
    isAuth = false,
    roles = [],
    requiredRole = '',
    Component = DEFAULT_COMPONENT,
    NoAccessComponent = DEFAULT_COMPONENT,
    redirectString = ''
  ) =>
  () => {
    return booleanToRenderComponent(
      isAuth,
      booleanToRenderComponent(
        roles.includes(requiredRole),
        <Component />,
        <NoAccessComponent />
      ),
      <Redirect to={redirectString} />
    );
  };

export const defaultFunctionWithReturnValue = (returnValue = {}) => returnValue;

export const payOutputToCostCenter = ({ payModel: { costCenter = '' } = {} } = {}) =>
  costCenter;

export const payRecordToCostCenters = (payRecord = {}) =>
  Object.values(payRecord).map(payOutputToCostCenter);

export const arrayToDedupedArray = (array) => Array.from(new Set(array));

export const stateToStateWithoutPrivateData = ({
  core = {},
  employeeData: { payRecord = {} } = {},
  leaderView = {},
}) =>
  new ReduxStateWithoutPrivateData(
    core,
    arrayToDedupedArray(payRecordToCostCenters(payRecord)),
    leaderView
  );

export const stateToSettings = ({ employeeData: { settings = [] } = {} } = {}) =>
  settings;

export const stateToShowSettings = ({ core: { showSettings = false } }) => showSettings;

export const stateToEmployeeId = ({
  core: {
    helixUser: { employeeId },
  },
}) => employeeId;

export const stateToHelixUserRoles = ({
  core: {
    helixUser: { roles = [] },
  },
}) => roles;

export const stringsMatch =
  (string = '') =>
  (string2 = '') =>
    string === string2;

export const valuesToIsExpired = (timestamp, { expiry = 0 }) => timestamp > expiry;

export const localStorageItemToIsPermanentItem = ({ ttl = 0 }) =>
  valueToIsEqual(PERMANENT_ITEM_TTL)(ttl);

export const valueToIsNotInLocalStorage = (item) => item === null;

export const numberAndNumberToSum = (number = 0, number2 = 0) => number + number2;

export const localStorageItemToValue = ({ value }) => value;

export const valuesToLocalStorageItemValue = (
  key,
  initialValue,
  now = 0,
  localStorage = {}
) => {
  try {
    const item = localStorage.getItem(key);
    if (valueToIsNotInLocalStorage(item)) return initialValue;
    const localStorageItem = JSON.parse(item);
    const value = localStorageItemToValue(localStorageItem);
    if (localStorageItemToIsPermanentItem(localStorageItem)) return value;
    if (valuesToIsExpired(now, localStorageItem)) {
      localStorage.removeItem(key);
      return initialValue;
    }
    return value;
  } catch (error) {
    return initialValue;
  }
};

export const keyToSetLocalStorageValue =
  (key) =>
  (value, ttl, now = Date.now()) =>
    localStorage.setItem(
      key,
      JSON.stringify(new LocalStorageItem(value, numberAndNumberToSum(ttl, now), ttl))
    );

// employeeIdToSettingsUrl :: String -> String
export const employeeIdToSettingsUrl = (emplId = '') => `${SETTINGS_API_PATH}/${emplId}`;

// helixUserToEmployeeId :: HelixUser -> String
export const helixUserToEmployeeId = ({ employeeId = '' }) => employeeId;

// payPerToSelectedPayPeriod :: PayPer -> String;
export const payPerToSelectedPayPeriod = ({ selectedPayPeriod = {} }) =>
  selectedPayPeriod;

// reduxStateToDarkMode :: ReduxState -> String
// export const reduxStateToDarkMode = ({
//   employeeData: { settings: { darkMode = false } = [] } = {},
// }) => darkMode;

// Setting -> String
export const settingToName = ({ name = '' }) => name;

// Setting -> String
export const settingToValue = ({ value = '' }) => value;

// String -> String
export const themeNameToContainerClass = (themeName = '') => {
  if (stringsMatch(themeName)(DARK_THEME_NAME)) return DARK_THEME_CLASS_NAME;
  return DEFAULT_THEME_CLASS_NAME;
};

// String -> Setting -> Bool
export const matchesSettingName =
  (nameMatch = '') =>
  (setting = new Setting()) =>
    stringsMatch(nameMatch)(settingToName(setting));

// String -> [Setting] -> Setting
export const settingNameAndSettingsToSetting =
  (settingName = '') =>
  (settings = []) =>
    settings.find(matchesSettingName(settingName)) || new Setting();

// [Setting] -> String
export const settingsToContainerClassName = (settings = []) => {
  const themeSetting = settingNameAndSettingsToSetting(THEME_SETTING_NAME)(settings);
  if (!matchesSettingName(THEME_SETTING_NAME)(themeSetting))
    return DEFAULT_THEME_CLASS_NAME;
  return themeNameToContainerClass(settingToValue(themeSetting));
};

// packageJsonToBuildVersion :: Object -> Stringn
export const packageJsonToBuildVersion = ({ dependencies = {} }) =>
  dependencies[PAY_PER_DEPENDENCY].replace(CARET, VERSION_PREFIX);

// locationToCustomState :: Location -> String
export const locationToCustomState = ({ search = '' } = {}) => search.split('=')[1];

export const hasPayRecord = (payRecord = {}) => arrayToHasLength(Object.keys(payRecord));

// settingsToSettings :: any -> [Setting]
// This is here to prevent dispatching old settings shape from i/o to Redux
export const settingsToSettings = (settings = []) => {
  if (Array.isArray(settings)) return settings;
  return [];
};
