// Combination queries are manually written

export const getEmployeeData = /* GraphQL */ `
  query GetData($employeeId: String!) {
    getSettings(employeeId: $employeeId) {
      employeeId
      settings
    }
    getPayViewData(employeeId: $employeeId) {
      employeeId
      employeeName
      employeePayPeriods
      teamMemberPayData
    }
  }
`;
