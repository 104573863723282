import { gunzipSync } from 'zlib';
import { EmployeeData, PayViewData, Setting } from '../../data/datatypes';
import { AppSyncQuery } from '../../data/datatypes/graphql';
import { getEmployeeData } from '../../graphql/combinationQueries';
import {
  getSettings,
  getPayViewData,
  getPayRules,
  getMetricDefinitions,
  getRoles,
  getUserPayPeriodDataRows,
  getUserPayPeriodDataRowsDetailed,
  getClients,
} from '../../graphql/queries';
import { mutateClient, mutateSettings } from '../../graphql/mutations';
import {
  EMPLOYEE_DATA_QUERY_TYPE,
  PAY_VIEW_DATA_QUERY_TYPE,
  SETTINGS_QUERY_TYPE,
  ADMIN_VIEW_PAYRULES_QUERY_TYPE,
  ADMIN_VIEW_METRIC_DEFINITIONS_QUERY_TYPE,
  ROLES_QUERY_TYPE,
  ADMIN_VIEW_PAY_RECORDS_QUERY_TYPE,
  ADMIN_VIEW_EMPLOYEE_PAY_RECORDS_QUERY_TYPE,
  CLIENT_MANAGEMENT_CLIENTS_QUERY_TYPE,
} from '../../io/appsync/constants';
import { stringsMatch } from '../core';

export const DEFAULT_SETTINGS = [];
export const DEFAULT_PAY_VIEW_DATA = new PayViewData();
export const DEFAULT_ROLES = [];

/**
 * Inflates responses from our lambda resolver that sends zlib deflated data
 *
 * @function inflateResponse
 * @param {String} response
 * @returns {Object}
 */
export const inflateResponse = (response = '') =>
  JSON.parse(gunzipSync(Buffer.from(response, 'base64')).toString('utf-8'));

export const isNull = (data) => data === null;
export const valueToHasNoPayViewData = (getPayViewDataResponse) =>
  getPayViewDataResponse === null;

export const valueToHasNoSettings = (getSettingsResponse) => getSettingsResponse === null;

export const getSettingsToSettings = ({ settings = '[]' } = {}) => JSON.parse(settings);

export const tupleToSetting = ([name, value]) => new Setting(name, value);

export const apiSettingsToSettings = (apiSettings = {}) =>
  Object.entries(apiSettings).map;

export const dataToSettings = ({ getSettings: getSettingsResponse = {} } = {}) => {
  if (valueToHasNoSettings(getSettingsResponse)) {
    return DEFAULT_SETTINGS;
  }
  return getSettingsToSettings(getSettingsResponse);
};

export const getPayViewDataResponseToPayViewData = ({
  teamMemberPayData = '',
  employeePayPeriods = '',
}) => new PayViewData(JSON.parse(employeePayPeriods), JSON.parse(teamMemberPayData));

export const dataToPayViewData = ({ getPayViewData: getPayViewDataResponse } = {}) => {
  if (valueToHasNoPayViewData(getPayViewDataResponse)) {
    return DEFAULT_PAY_VIEW_DATA;
  }
  return getPayViewDataResponseToPayViewData(getPayViewDataResponse);
};

export const getRolesResponseToRoles = ({ roles = [] }) => roles;

export const dataToRoles = ({ getRoles: { value } }) =>
  inflateResponse(value).length > 0
    ? getRolesResponseToRoles(inflateResponse(value)[0])
    : DEFAULT_ROLES;

// export const dataToPayViewData = ({ getPayViewData: getPayViewDataResponse = {} } = {}) =>
//   getPayViewDataResponse
//     ? getPayViewDataResponseToPayViewData(getPayViewDataResponse)
//     : DEFAULT_PAY_VIEW_DATA;

export const employeeDataResponseToEmployeeData = ({
  data = { getSettings, getPayViewData },
} = {}) => {
  return new EmployeeData(dataToSettings(data), dataToPayViewData(data));
};

export const queryTypeToAppSyncQuery = (queryType = '', variables = {}) => {
  const valueToIsQueryType = stringsMatch(queryType);
  if (valueToIsQueryType(SETTINGS_QUERY_TYPE))
    return new AppSyncQuery(getSettings, variables);
  if (valueToIsQueryType(PAY_VIEW_DATA_QUERY_TYPE))
    return new AppSyncQuery(getPayViewData, variables);
  if (valueToIsQueryType(EMPLOYEE_DATA_QUERY_TYPE))
    return new AppSyncQuery(getEmployeeData, variables);
  if (valueToIsQueryType(ROLES_QUERY_TYPE)) return new AppSyncQuery(getRoles, variables);
  // query types for admin view
  if (valueToIsQueryType(ADMIN_VIEW_PAYRULES_QUERY_TYPE))
    return new AppSyncQuery(getPayRules, variables);
  if (valueToIsQueryType(ADMIN_VIEW_METRIC_DEFINITIONS_QUERY_TYPE))
    return new AppSyncQuery(getMetricDefinitions, variables);
  if (valueToIsQueryType(ADMIN_VIEW_PAY_RECORDS_QUERY_TYPE))
    return new AppSyncQuery(getUserPayPeriodDataRows, variables);
  if (valueToIsQueryType(ADMIN_VIEW_EMPLOYEE_PAY_RECORDS_QUERY_TYPE))
    return new AppSyncQuery(getUserPayPeriodDataRowsDetailed, variables);
  // query types for client management view
  if (valueToIsQueryType(CLIENT_MANAGEMENT_CLIENTS_QUERY_TYPE))
    return new AppSyncQuery(getClients, variables);
  return new AppSyncQuery();
};

export const mutationTypeToAppSyncMutation = (mutationType = '', variables = {}) => {
  const valueToIsMutationType = stringsMatch(mutationType);
  if (valueToIsMutationType(SETTINGS_QUERY_TYPE))
    return new AppSyncQuery(mutateSettings, variables);
  if (valueToIsMutationType(CLIENT_MANAGEMENT_CLIENTS_QUERY_TYPE))
    return new AppSyncQuery(mutateClient, variables);
  return new AppSyncQuery();
};
