import Amplify from 'aws-amplify';
import { Subject } from 'rxjs';
import config from '../config.json';
import { HelixDataObservable, DEFAULT_FUNCTION, EmployeeData } from '../data/datatypes';
import { DEFAULT_PROMISE } from '../data/constants';
import { envToStage } from '../transformations/core';
import { appSyncQueryPromise } from './appsync';
import {
  employeeDataResponseToEmployeeData,
  queryTypeToAppSyncQuery,
} from '../transformations/graphql';

export const employeeDataAppSyncSubject = new Subject();

Amplify.configure(config[envToStage(process.env)].API);

export const promiseToEmitData =
  (subject = {}) =>
    async (promise = DEFAULT_PROMISE) => {
      try {
        const response = await promise;
        return subject.next(employeeDataResponseToEmployeeData(response));
      } catch (error) {
        console.log(error);
        return subject.next(new EmployeeData());
      }
    };

export const subjectToGetData = () => employeeDataAppSyncSubject.asObservable();

export const functionsAndSubjectToEmitAppSyncData =
  (promiseTransformation = DEFAULT_FUNCTION) =>
    async (queryType = '', variables = {}) => {
      promiseTransformation(
        appSyncQueryPromise(queryTypeToAppSyncQuery(queryType, variables))
      );
    };

export const employeeDataObservableAppSync = new HelixDataObservable(
  functionsAndSubjectToEmitAppSyncData(promiseToEmitData(employeeDataAppSyncSubject)),
  subjectToGetData
);
