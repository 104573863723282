import React from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';

import config from '../../config.json';
import { envToStage, locationToCustomState } from '../../transformations/core';

const STAGE = envToStage(process.env);

Amplify.configure(config[STAGE].Amplify);

export const SSO = () => {
  const location = useLocation();
  Auth.federatedSignIn({
    provider: config[STAGE].Amplify.Auth.oauth.identityProvider,
    customState: locationToCustomState(location),
  });
  return <React.Fragment />;
};

export default SSO;
