/* eslint-disable no-unused-vars */
import {
  PayRule,
  Operation,
  UserPayPeriodDataRow,
  PayMetricDetail,
  PayRuleDetail,
  MetricDefinition,
} from '@helix/datatypes';
/* eslint-enable no-unused-vars */
import { AppSyncQuery } from '../../../data/datatypes/graphql';
import {
  ADMIN_VIEW_PAY_RECORDS_QUERY_TYPE,
  ADMIN_VIEW_METRIC_DEFINITIONS_QUERY_TYPE,
  ADMIN_VIEW_PAYRULES_QUERY_TYPE,
  ADMIN_VIEW_EMPLOYEE_PAY_RECORDS_QUERY_TYPE,
} from '../../../io/appsync/constants';
import {
  queryTypeToAppSyncQuery,
  inflateResponse,
} from '../../../transformations/graphql';
import { DEFAULT_QUERY } from '../constants';

/**
 * @function stringEqualityDecisionCaseInsensitive
 * @param {String} string1
 * @param {String} string2
 * @returns {Boolean}
 */
export const stringEqualityDecisionCaseInsensitive = (string1 = '', string2 = '') =>
  string1.toLowerCase() === string2.toLowerCase();

/**
 * @function dateToDateStringWithoutTime
 * @param {Date} date
 * @returns {String}
 */
const dateToDateStringWithoutTime = (date = new Date()) =>
  date.toISOString().split('T')[0];

/**
 * Returns a properly formatted string for the day value of a YYYY-mm-dd datestring
 *
 * @function numberToDateStringPaddedNumber
 * @param {Number} number
 * @returns {String}
 */
const numberToDateStringPaddedNumber = (number = 1) =>
  number < 10 ? `0${number}` : `${number}`;

/**
 * Returns YYYY substring from YYYY-mm-dd format datestring
 *
 * @function dateStringToYear
 * @param {String} datestring
 * @returns {String}
 */
const dateStringToYear = (datestring = '') => datestring.split('-')[0];

/**
 * Returns mm substring from YYYY-mm-dd format datestring
 *
 * @function dateStringToMonth
 * @param {String} datestring
 * @returns {String}
 */
const dateStringToMonth = (datestring = '') => datestring.split('-')[1];

/**
 * Returns a YYYY-mm-dd format datestring for the given datestring and day number
 *
 * @function dateStringAndDayNumberToDateString
 * @param {String} datestring
 * @param {Number} dayNumber
 * @returns {String}
 */
const dateStringAndDayNumberToDateString = (datestring = '', dayNumber = 1) =>
  `${dateStringToYear(datestring)}-${dateStringToMonth(
    datestring
  )}-${numberToDateStringPaddedNumber(dayNumber)}`;

/**
 * Returns the total number of days in the month for the given YYYY-mm-dd format datestring
 *
 * @function dateStringToDaysInMonthNumber
 * @param {String} datestring
 * @returns {Number}
 */
const dateStringToDaysInMonthNumber = (datestring = '') =>
  new Date(
    Number(dateStringToYear(datestring)),
    Number(dateStringToMonth(datestring)),
    0
  ).getDate();

/**
 * @function dateToDateAppSyncQueryVariables
 * @param {Date} date
 * @returns {Object}
 *
 * Returns the selected date in AppSyncQuery variable format
 */
export const dateToStartDateAppSyncQueryVariable = (date = new Date()) => ({
  startDate: dateToDateStringWithoutTime(date),
});

/**
 * @function dateToStartAndEndDateAppSyncQueryVariables
 * @param {Date} date
 * @returns {Object}
 *
 * Returns the selected dates in AppSyncQuery variable format
 */
export const dateToStartAndEndDateAppSyncQueryVariables = (date = new Date()) => ({
  startDate: dateStringAndDayNumberToDateString(dateToDateStringWithoutTime(date), 1),
  endDate: dateStringAndDayNumberToDateString(
    dateToDateStringWithoutTime(date),
    dateStringToDaysInMonthNumber(dateToDateStringWithoutTime(date))
  ),
});

/**
 * @function employeeIdAndDateToEmployeeIdAndStartAndEndDateAppSyncQueryVariables
 * @param {String} employeeId
 * @param {Date} date
 * @returns {Object}
 *
 * Returns the selected employeeId and dates in AppSyncQuery variable format
 */
export const employeeIdAndDateToEmployeeIdAndStartAndEndDateAppSyncQueryVariables = (
  employeeId = '',
  date = new Date()
) => ({
  employeeId: employeeId.trim(),
  startDate: dateStringAndDayNumberToDateString(dateToDateStringWithoutTime(date), 1),
  endDate: dateStringAndDayNumberToDateString(
    dateToDateStringWithoutTime(date),
    dateStringToDaysInMonthNumber(dateToDateStringWithoutTime(date))
  ),
});

/**
 * @function stringAndDateToAppSyncQuery
 * @param {String} selectedQueryType
 * @param {Date} selectedDate
 * @returns {AppSyncQuery}
 *
 * Function taking the value of selectedQueryType and all defined UI controls whose
 * value is used to constrain a queryType and returning the relevant AppSyncQuery
 */
export const stringAndDateAndStringToAppSyncQuery = (
  selectedQueryType = '',
  selectedDate = new Date(),
  selectedEmployeeId = ''
) => {
  if (
    stringEqualityDecisionCaseInsensitive(
      selectedQueryType,
      ADMIN_VIEW_PAYRULES_QUERY_TYPE
    )
  )
    return queryTypeToAppSyncQuery(
      ADMIN_VIEW_PAYRULES_QUERY_TYPE,
      dateToStartDateAppSyncQueryVariable(selectedDate)
    );

  if (
    stringEqualityDecisionCaseInsensitive(
      selectedQueryType,
      ADMIN_VIEW_METRIC_DEFINITIONS_QUERY_TYPE
    )
  )
    return queryTypeToAppSyncQuery(ADMIN_VIEW_METRIC_DEFINITIONS_QUERY_TYPE);

  if (
    stringEqualityDecisionCaseInsensitive(
      selectedQueryType,
      ADMIN_VIEW_PAY_RECORDS_QUERY_TYPE
    )
  )
    return queryTypeToAppSyncQuery(
      ADMIN_VIEW_PAY_RECORDS_QUERY_TYPE,
      dateToStartAndEndDateAppSyncQueryVariables(selectedDate)
    );

  // TODO: make sure this works
  if (
    stringEqualityDecisionCaseInsensitive(
      selectedQueryType,
      ADMIN_VIEW_EMPLOYEE_PAY_RECORDS_QUERY_TYPE
    )
  )
    return queryTypeToAppSyncQuery(
      ADMIN_VIEW_EMPLOYEE_PAY_RECORDS_QUERY_TYPE,
      employeeIdAndDateToEmployeeIdAndStartAndEndDateAppSyncQueryVariables(
        selectedEmployeeId,
        selectedDate
      )
    );

  return new AppSyncQuery(DEFAULT_QUERY);
};

/**
 * @function operationObjectToOperation
 * @param {Object} operationObject
 * @returns {Operation}
 */
const operationObjectToOperation = ({ name, type, target, operator, value }) =>
  new Operation(name, type, target, operator, value);

/**
 * @function payRuleObjectToPayRule
 * @param {Object} payRuleObject
 * @returns {PayRule}
 */
const payRuleObjectToPayRule = ({ name, startDate, conditions, adjustments }) =>
  new PayRule(
    name,
    conditions.map(operationObjectToOperation),
    adjustments.map(operationObjectToOperation),
    startDate
  );

/**
 * @function graphqlResponseToPayRules
 * @param {Object} graphqlResponse
 * @returns {PayRule[]}
 */
export const graphqlResponseToPayRules = ({
  data: {
    getPayRules: { value },
  },
}) => inflateResponse(value).map(payRuleObjectToPayRule);

/**
 * @function metricDefinitionObjectToMetricDefinition
 * @param {Object} metricDefinitionObject
 * @returns {MetricDefinition}
 */
const metricDefinitionObjectToMetricDefinition = ({ name, metricNames, expression }) =>
  new MetricDefinition(name, metricNames, expression);

/**
 * @function graphqlResponseToMetricDefinitions
 * @param {Object} graphqlResponse
 * @returns {Object}
 */
export const graphqlResponseToMetricDefinitions = ({ data: { getMetricDefinitions } }) =>
  getMetricDefinitions.map(metricDefinitionObjectToMetricDefinition);

/**
 * @function payMetricDetailObjectToPayMetricDetail
 * @param {Object} payMetricDetailObject
 * @returns {PayMetricDetail}
 */
const payMetricDetailObjectToPayMetricDetail = ({
  metricName,
  metricValue,
  startDate,
  endDate,
}) => new PayMetricDetail(metricName, metricValue, startDate, endDate);

/**
 * @function payMetricDetailObjectToPayMetricDetail
 * @param {Object} payRuleDetailObject
 * @returns {PayRuleDetail}
 */
const payRuleDetailObjectToPayRuleDetail = ({
  payRuleName,
  conditionFormula,
  payFormula,
}) => new PayRuleDetail(payRuleName, conditionFormula, payFormula);

const userPayPeriodDataRowObjectToUserPayPeriodDataRow = ({
  employeeId,
  startDate,
  endDate,
  payModelName,
  totalCalls,
  sales,
  totalPay,
  serveMetricName,
  serveValue,
  servePay,
  sp100MetricName,
  sp100Value,
  sp100Pay,
  heroValue,
  heroPay,
  payMetricDetails = [],
  payRuleDetails = [],
}) =>
  new UserPayPeriodDataRow(
    employeeId,
    startDate,
    endDate,
    payModelName,
    totalCalls,
    sales,
    totalPay,
    serveMetricName,
    serveValue,
    servePay,
    sp100MetricName,
    sp100Value,
    sp100Pay,
    heroValue,
    heroPay,
    payMetricDetails.map(payMetricDetailObjectToPayMetricDetail),
    payRuleDetails.map(payRuleDetailObjectToPayRuleDetail)
  );

/**
 * @function graphqlResponseToUserPayPeriodDataRows
 * @param {Object} graphqlResponse
 * @returns {UserPayPeriodDataRow[]}
 */
export const graphqlResponseToUserPayPeriodDataRows = ({
  data: {
    getUserPayPeriodDataRows: { value },
  },
}) => inflateResponse(value).map(userPayPeriodDataRowObjectToUserPayPeriodDataRow);

/**
 * @function graphqlResponseToUserPayPeriodDataRowsDetailed
 * @param {Object} graphqlResponse
 */
export const graphqlResponseToClients = ({
  data: {
    getClients: { value },
  },
}) => inflateResponse(value);
