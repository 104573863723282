/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const mutateSettings = /* GraphQL */ `
  mutation MutateSettings($employeeId: String!, $settings: AWSJSON) {
    mutateSettings(employeeId: $employeeId, settings: $settings) {
      employeeId
      settings
    }
  }
`;
export const mutateClient = /* GraphQL */ `
  mutation MutateClient(
    $name: String!
    $operationSchedule: AWSJSON
    $author: String
  ) {
    mutateClient(
      name: $name
      operationSchedule: $operationSchedule
      author: $author
    ) {
      value
    }
  }
`;
