/* eslint-disable indent */
import { ReduxAction } from '@helix/react-lib';
import { payViewDataToPayViewDataWithSortedPerformanceMetrics } from '@helix/payDashboardDatatypes';
import { EmployeeData, PayViewData, Setting } from '../../data/datatypes';
import {
  stringsMatch,
  matchesSettingName,
  settingToName,
} from '../../transformations/core';

export const INITIAL_EMPLOYEE_DATA_STATE = new EmployeeData();
export const SETTINGS = 'SETTINGS';
export const PAY_VIEW_DATA = 'PAY_VIEW_DATA';
export const EMPLOYEE_DATA = 'EMPLOYEE_DATA';

export const settingsToReduxAction = (settings = []) =>
  new ReduxAction(SETTINGS, settings);

// String -> Setting -> Boolean
export const isNotNamedSetting =
  (settingName = '') =>
  (setting = new Setting()) =>
    !matchesSettingName(settingName)(setting);

export const currentSettingsAndSettingToReduxAction = (
  settings = [],
  setting = new Setting()
) =>
  settingsToReduxAction(
    settings.filter(isNotNamedSetting(settingToName(setting))).concat(setting)
  );

export const payViewDataToReduxAction = (payViewData = new PayViewData()) =>
  new ReduxAction(PAY_VIEW_DATA, payViewData);

export const employeeDataToReduxAction = (employeeData = new EmployeeData()) =>
  new ReduxAction(EMPLOYEE_DATA, employeeData);

export const setSettings = (state, settings) => ({ ...state, settings });

export const setPayViewData = (state, payViewData = new PayViewData()) => ({
  ...state,
  payViewData,
});

export const setEmployeeData = (state, employeeData = new EmployeeData()) => ({
  ...state,
  ...employeeData,
});

export const employeeDataReducer = (
  state = INITIAL_EMPLOYEE_DATA_STATE,
  { type = '', payload = {} } = {}
) => {
  const valueToIsActionType = stringsMatch(type);
  if (valueToIsActionType(SETTINGS)) return setSettings(state, payload);
  if (valueToIsActionType(PAY_VIEW_DATA))
    return setPayViewData(
      state,
      payViewDataToPayViewDataWithSortedPerformanceMetrics(payload)
    );
  if (valueToIsActionType(EMPLOYEE_DATA)) return setEmployeeData(state, payload);
  return state;
};

export default employeeDataReducer;
