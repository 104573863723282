import React from 'react';
import './index.css';
import { HelixLogoAppNameAndBuildVersionComponent } from '../HelixLogoAppNameAndBuildVersionComponent';
import { SettingsIconAndDropdownComponent } from '../SettingsIconAndDropdownComponent';

export const HeaderComponent = ({ appNameAndBuildVersion = {}, settingsAndHandlers = {} }) => (
  <header className='header'>
    <HelixLogoAppNameAndBuildVersionComponent {...appNameAndBuildVersion} />
    <SettingsIconAndDropdownComponent {...settingsAndHandlers} />
  </header>
);
