import { LeaderReducer } from '@helix/paydashboard-ui';
import { AdminDataViewReduxStateReducer } from '@helix/admin-data-view-ui';
import { combineReducers } from 'redux';
import { ReduxState } from '../data/datatypes';
import CoreReducer from './CoreReducer';
import EmployeeDataReducer from './EmployeeDataReducer';

export default combineReducers(
  new ReduxState(
    CoreReducer,
    EmployeeDataReducer,
    LeaderReducer,
    AdminDataViewReduxStateReducer
  )
);
