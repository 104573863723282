/* eslint-disable indent */
import React, { useState } from 'react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SignInPayload } from '../../../data/datatypes';
import { useLocalStorage } from '../../../hooks/UseLocalStorage';
import config from '../../../config.json';
import { signInPayloadToSignInAction } from '../../../reducers/CoreReducer';
import {
  envToStage,
  dataToRedirectUrl,
  payloadToHelixUser,
  awsUserToPayload,
  helixUserToEmployeeId,
} from '../../../transformations/core';
import {
  HELIXUSER_KEY,
  AUTHENTICATED_KEY,
  INITIAL_AUTHENTICATED_VALUE,
  INITIAL_HELIXUSER_VALUE,
} from '../../../data/constants';
import { appSyncQueryPromise } from '../../../io/appsync';
import { AppSyncQuery } from '../../../data/datatypes/graphql';
import { getRoles } from '../../../graphql/queries';
import { dataToRoles } from '../../../transformations/graphql';

Amplify.configure(config[envToStage(process.env)].Amplify);

export const FIFTY_MIN_IN_MILLIS = 50 * 60 * 1000;
export const DEFAULT_PREVIOUS_PAGE_ROUTE = '/';

export const redirectOnAuth = (history = {}, redirectUrl = '') => {
  // replace previous page history with splash screen so that if a user
  // navigates back a page they aren't going to the expired ping login page
  history.replace(DEFAULT_PREVIOUS_PAGE_ROUTE);

  // redirect user to intended app
  history.push(redirectUrl);
};

export const signIn = async (
  dispatch,
  setAuthenticated,
  setHelixUser,
  history,
  redirect
) => {
  setAuthenticated(true, FIFTY_MIN_IN_MILLIS);
  const awsUser = await Auth.currentAuthenticatedUser();
  const helixUser = payloadToHelixUser(awsUserToPayload(awsUser));
  const { data } = await appSyncQueryPromise(
    new AppSyncQuery(getRoles, { employeeId: helixUserToEmployeeId(helixUser) })
  );
  helixUser.roles = dataToRoles(data);
  setHelixUser(helixUser);
  dispatch(signInPayloadToSignInAction(new SignInPayload(helixUser, true)));
  if (redirect && redirect.length > 0) redirectOnAuth(history, redirect);
};

export const AuthHub = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [, setAuthenticated] = useLocalStorage(
    AUTHENTICATED_KEY,
    INITIAL_AUTHENTICATED_VALUE
  );
  const [, setHelixUser] = useLocalStorage(HELIXUSER_KEY, INITIAL_HELIXUSER_VALUE);
  const [redirect, setRedirect] = useState('');

  const listener = async ({ payload: { event = '', data = '' } = {} } = {}) => {
    switch (event) {
      case 'signIn':
        await signIn(dispatch, setAuthenticated, setHelixUser, history, redirect);
        break;
      case 'signIn_failure':
        break;
      case 'customOAuthState':
        setRedirect(dataToRedirectUrl(data));
        break;
      default:
        break;
    }
  };

  Hub.listen('auth', listener);
  return <React.Fragment />;
};

export default AuthHub;
