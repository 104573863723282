import React from 'react';
import { matchesSettingName, THEME_SETTING_NAME } from '../../transformations/core';
import { ThemeSettingComponent } from '../ThemeSettingComponent';
import './index.css';

// [Setting] -> Setting
const settingsToThemeSetting = (settings) => settings
  .find(matchesSettingName(THEME_SETTING_NAME));

export const SettingsDropdownComponent = ({ settings, changeHandler }) => {
  const themeSetting = settingsToThemeSetting(settings);

  return (
    <div className="text-and-toggle-event-handler-list">
      <ThemeSettingComponent theme={themeSetting} changeHandler={changeHandler} />
    </div>
  );

};
