/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { MultiStepClientForm } from '@helix/client-form';
import { useSelector } from 'react-redux';
import { CLIENT_MANAGEMENT_CLIENTS_QUERY_TYPE } from '../../io/appsync/constants';
import { appSyncQueryPromise } from '../../io/appsync';
import {
  queryTypeToAppSyncQuery,
  mutationTypeToAppSyncMutation,
} from '../../transformations/graphql';
import { graphqlResponseToClients } from './transformations/appSync';
import { reduxStateToHelixUser } from '../../data/datatypes';
import { helixUserToAuthor } from './transformations';
import './index.css';

const retrieveClients = () =>
  (async () =>
    appSyncQueryPromise(queryTypeToAppSyncQuery(CLIENT_MANAGEMENT_CLIENTS_QUERY_TYPE)))();

export const ClientManagementViewContainer = () => {
  // @ts-ignore
  const [initialLoad, setInitialLoad] = useState(true);
  const [clients, setClients] = useState([]);
  const helixUser = useSelector(reduxStateToHelixUser);

  useEffect(() => {
    if (initialLoad) {
      // initial query
      retrieveClients().then(graphqlResponseToClients).then(setClients);
      setInitialLoad(false);
    }
  }, [initialLoad]);

  const formCompleteHandler = ({ name, operationSchedule }) =>
    appSyncQueryPromise(
      mutationTypeToAppSyncMutation(CLIENT_MANAGEMENT_CLIENTS_QUERY_TYPE, {
        // @ts-ignore
        author: helixUserToAuthor(helixUser),
        name,
        operationSchedule: JSON.stringify(operationSchedule),
      })
    ).then(() => retrieveClients().then(graphqlResponseToClients).then(setClients));

  return (
    <div id="client-management-container">
      <MultiStepClientForm clients={clients} formCompleteHandler={formCompleteHandler} />
    </div>
  );
};

export default ClientManagementViewContainer;
