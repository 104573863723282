export const SETTINGS_QUERY_TYPE = 'settings';
export const EMPLOYEE_DATA_QUERY_TYPE = 'employeeData';
export const PAY_VIEW_DATA_QUERY_TYPE = 'payViewData';
export const ROLES_QUERY_TYPE = 'roles';
export const ADMIN_VIEW_PAYRULES_QUERY_TYPE = 'payRules';
export const ADMIN_VIEW_METRIC_DEFINITIONS_QUERY_TYPE = 'metricDefinitions';
export const ADMIN_VIEW_PAY_RECORDS_QUERY_TYPE = 'payRecords';
export const ADMIN_VIEW_EMPLOYEE_PAY_RECORDS_QUERY_TYPE = 'payRecordDetail';

export const CLIENT_MANAGEMENT_CLIENTS_QUERY_TYPE = 'clients';
