import { createStore, applyMiddleware } from 'redux';
import reducer from '../../reducers';

export const LOCAL_STAGE = 'LOCAL';

const classToActionMiddleWare = () => (next) => (action) => next({ ...action });

export const valueToIsLocalState = (state = '') => state === LOCAL_STAGE;

export const appStageAndStateToReduxStore = (stage = '', state = {}) => {
  if (valueToIsLocalState(stage)) {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return createStore(
      reducer,
      { ...state },
      composeWithDevTools(applyMiddleware(classToActionMiddleWare))
    );
  }
  return createStore(reducer, { ...state }, applyMiddleware(classToActionMiddleWare));
};
