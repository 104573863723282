import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import icecreamFilled from '../data/images/icecream_filled.svg';
import search from '../data/images/search.svg';
import { SSO } from '../components/SSO';
import { LeaderContainer } from '../containers/LeaderContainer';
import { PayRetiredContainer } from '../containers/PayRetiredContainer';
import { AuthHub } from '../components/SSO/AuthHub';
import {
  valuesToRender,
  stateToAuthenticated,
  locationToRedirectString,
  stateToEmployeeId,
  valuesToValuesToRender,
} from '../transformations/core';
import { NotFoundComponent } from '../components/NotFoundComponent';
import { NO_PAY_RECORD_PATH } from '../data/constants';
import { Content, NotFound } from '../data/datatypes/core';
import {
  AdminDataViewContainer,
  NotAdminComponent,
} from '../containers/AdminDataViewContainer';
import { appSyncQueryPromise } from '../io/appsync';
import { dataToRoles, queryTypeToAppSyncQuery } from '../transformations/graphql';
import { ROLES_QUERY_TYPE } from '../io/appsync/constants';
import { ADMIN_DATA_VIEW_ACCESS_ROLE } from '../containers/AdminDataViewContainer/constants';
import { rolesToSetHelixUserRolesReduxAction } from '../reducers/CoreReducer';
import ClientManagementViewContainer from '../containers/ClientManagementViewContainer';

export const AUTH_HUB_PATH = '/';
export const SSO_PATH = '/auth';
export const LEADER_CONTAINER_PATH = '/pay';
export const LEADER_CONTAINER_REDIRECT_PATH = '/payper';
export const ADMIN_DATA_VIEW_PATH = '/data';
export const ADMIN_CLIENTS_VIEW_PATH = '/clients';

export const NO_PAGE_FOUND_PATH = '/no-page-found';
export const ANONYMOUS_ROUTE_PATH = '*';
export const NO_PAY_RECORD_FOUND_CONTENT = new Content(
  'Whoops!',
  'No pay record found.',
  'Questions? Please contact your leader. Thank you!'
);
export const NO_PAGE_FOUND_CONTENT = new Content(
  'Nothing to see here!',
  // eslint-disable-next-line quotes
  "We couldn't find the page you're looking for!"
);
export const NO_PAY_RECORD_FOUND = new NotFound(
  NO_PAY_RECORD_FOUND_CONTENT,
  icecreamFilled
);
export const NO_PAGE_FOUND = new NotFound(NO_PAGE_FOUND_CONTENT, search);
export const NoPayRecordFoundComponent = () => NotFoundComponent(NO_PAY_RECORD_FOUND);
export const NoPageFoundComponent = () => NotFoundComponent(NO_PAGE_FOUND);

export const PrivateRoute = ({ component: Component, path = '' }) => {
  const render = valuesToRender(
    useSelector(stateToAuthenticated),
    Component,
    locationToRedirectString(useLocation())
  );
  return <Route path={path} render={render} />;
};

export const RoleBasedRoute = ({
  component: Component,
  noAccessComponent: NoAccessComponent,
  path = '',
  requiredRole = '',
}) => {
  const employeeId = useSelector(stateToEmployeeId);
  const authenticated = useSelector(stateToAuthenticated);
  const location = useLocation();
  const [roles, setRoles] = useState(['']);
  const dispatch = useDispatch();

  // IO for user roles
  useEffect(() => {
    if (employeeId) {
      appSyncQueryPromise(queryTypeToAppSyncQuery(ROLES_QUERY_TYPE, { employeeId }))
        // @ts-ignore
        .then(({ data }) => {
          setRoles(dataToRoles(data));
          dispatch(rolesToSetHelixUserRolesReduxAction(dataToRoles(data)));
        })
        .catch(() => dispatch(rolesToSetHelixUserRolesReduxAction([])));
    }
  }, [employeeId, dispatch]);

  const render = valuesToValuesToRender(
    authenticated,
    roles,
    requiredRole,
    Component,
    NoAccessComponent,
    locationToRedirectString(location)
  );

  return <Route path={path} render={render} />;
};

export const Routes = () => (
  <Router forceRefresh>
    <Switch>
      <Route path={AUTH_HUB_PATH} exact component={AuthHub} />
      <Route path={SSO_PATH} exact component={SSO} />
      <Route path={NO_PAY_RECORD_PATH} exact component={NoPayRecordFoundComponent} />
      <Route path={NO_PAGE_FOUND_PATH} exact component={NoPageFoundComponent} />
      <PrivateRoute path={LEADER_CONTAINER_PATH} component={PayRetiredContainer} />
      <RoleBasedRoute
        path={ADMIN_DATA_VIEW_PATH}
        component={AdminDataViewContainer}
        noAccessComponent={NotAdminComponent}
        requiredRole={ADMIN_DATA_VIEW_ACCESS_ROLE}
      />
      <RoleBasedRoute
        path={ADMIN_CLIENTS_VIEW_PATH}
        component={ClientManagementViewContainer}
        noAccessComponent={NotAdminComponent}
        requiredRole={ADMIN_DATA_VIEW_ACCESS_ROLE}
      />
      <Redirect from={LEADER_CONTAINER_REDIRECT_PATH} to={LEADER_CONTAINER_PATH} />
      <Redirect from={ANONYMOUS_ROUTE_PATH} to={NO_PAGE_FOUND_PATH} />
    </Switch>
  </Router>
);

export default Routes;
