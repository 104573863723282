import {
  ADMIN_VIEW_METRIC_DEFINITIONS_QUERY_TYPE,
  ADMIN_VIEW_PAYRULES_QUERY_TYPE,
  ADMIN_VIEW_PAY_RECORDS_QUERY_TYPE,
  ADMIN_VIEW_EMPLOYEE_PAY_RECORDS_QUERY_TYPE,
} from '../../io/appsync/constants';

export const displayViewTypes = [
  ADMIN_VIEW_METRIC_DEFINITIONS_QUERY_TYPE,
  ADMIN_VIEW_PAYRULES_QUERY_TYPE,
  ADMIN_VIEW_PAY_RECORDS_QUERY_TYPE,
  ADMIN_VIEW_EMPLOYEE_PAY_RECORDS_QUERY_TYPE,
];

export const ADMIN_DATA_VIEW_ACCESS_ROLE = 'superAdmin';

export const DEFAULT_FUNCTION = () => {};

export const DEFAULT_QUERY = '';
