import React from 'react';

import './index.css';

export const NOT_FOUND_CLASS = 'not-found';
export const NOT_FOUND_CONTENT_CLASS = 'not-found-content';
export const NOT_FOUND_IMAGE_CLASS = 'no-image';
export const NOT_FOUND_IMAGE_ALT = 'ice cream cone on the ground';

export const ContentComponent = ({ title = '', message = '', feedback = '' }) => (
  <div className={NOT_FOUND_CONTENT_CLASS} before={title} after={feedback}>
    {message}
  </div>
);

export const NotFoundComponent = ({ content = {}, src = '' }) => {
  return (
    <div className={NOT_FOUND_CLASS}>
      <ContentComponent {...content} />
      <img src={src} className={NOT_FOUND_IMAGE_CLASS} alt={NOT_FOUND_IMAGE_ALT} />
    </div>
  );
};

export default NotFoundComponent;
