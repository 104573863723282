/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import {
  MetricDefinition,
  Operation,
  operationToOperator,
  operationToTarget,
  operationToValue,
  PayMetricDetail,
  payMetricDetailToMetricName,
  PayRule,
  PayRuleDetail,
  PayRuleAndPayMetricDetail,
  UserPayPeriodDataRow,
  payRuleDetailToPayRuleName,
  payRuleDetailToConditionFormula,
  payRuleDetailToPayFormula,
  payMetricDetailToMetricValue,
  payMetricDetailToStartDate,
  payMetricDetailToEndDate,
} from '@helix/datatypes';
import {
  MetricDefinitionDisplay,
  PayRuleDisplay,
  PayRecordDisplay,
  PayRecordDetailDisplay,
  dateToQueryControlsDateValueChangeAction,
  stringToQueryControlsDropdownValueChangeAction,
  metricDefinitionDisplaysToMetricDefinitionDisplaysChangeAction,
  booleanToIsDataLoadingChangeAction,
  booleanToHasAnyQueryRunChangeAction,
  emptyAllDisplayValuesAction,
  payRuleDisplaysToPayRuleDisplaysChangeAction,
  payRecordDisplaysToPayRecordDisplaysChangeAction,
  payRecordDetailDisplaysToPayRecordDetailDisplaysChangeAction,
  AdminDataViewReduxState,
  stringsToQueryControlsFilterValuesChangeAction,
  stringToViewControlsTableViewCountChangeAction,
  stringToSelectedEmployeeIdChangeAction,
  TABLE_VIEW_COUNT_OPTIONS,
} from '@helix/admin-data-view-ui';
/* eslint-enable no-unused-vars */
import {
  ADMIN_VIEW_METRIC_DEFINITIONS_QUERY_TYPE,
  ADMIN_VIEW_PAYRULES_QUERY_TYPE,
  ADMIN_VIEW_PAY_RECORDS_QUERY_TYPE,
  // ADMIN_VIEW_PAY_RECORD_DETAIL_QUERY_TYPE,
  ADMIN_VIEW_EMPLOYEE_PAY_RECORDS_QUERY_TYPE,
} from '../../../io/appsync/constants';
import { displayViewTypes } from '../constants';
import { appSyncQueryPromise } from '../../../io/appsync';
import {
  graphqlResponseToMetricDefinitions,
  graphqlResponseToPayRules,
  graphqlResponseToUserPayPeriodDataRows,
  graphqlResponseToUserPayPeriodDataRowsDetailed,
  stringAndDateAndStringToAppSyncQuery,
  stringEqualityDecisionCaseInsensitive,
} from './appSync';
/**
 * Returns lowercase representation of String
 *
 * stringToLowerCase :: String -> String
 * @param {String} string
 * @returns {String}
 */
export const stringToLowerCase = (string) => string.toLowerCase();

/**
 * @function valueIsTrue
 * @param {Boolean} boolean
 * @returns {Boolean}
 */
export const valueIsTrue = (boolean = false) => boolean === true;
/**
 * @function booleansToBoolean
 * @param {Boolean[]} values
 * @returns {Boolean}
 */
export const booleansToBoolean = (values = []) => values.every(valueIsTrue);
/**
 * @function objectAndFilterToBoolean
 * @param {Object} object
 * @returns {function(String): Boolean}
 */
const objectAndFilterToBoolean =
  (object = {}) =>
    (filter = '') =>
      stringToLowerCase(JSON.stringify(object)).includes(stringToLowerCase(filter));

/**
 * @function filtersAndObjectToBoolean
 * @param {String[]} filters
 * @returns {function(Object): Boolean}
 */
export const filtersAndObjectToBoolean =
  (filters = []) =>
    (object = {}) =>
      booleansToBoolean(filters.map(objectAndFilterToBoolean(object)));

/**
 * @function metricDefinitionToMetricDefinitionDisplay
 * @param {MetricDefinition} metricDefinition
 * @returns {MetricDefinitionDisplay}
 */
export const metricDefinitionToMetricDefinitionDisplay = (
  { name, metricNames, expression } = new MetricDefinition()
) => new MetricDefinitionDisplay(name, metricNames.join(', '), expression);

/**
 * @function operationToDisplayFormattedString
 * @param {Operation} operation
 * @returns {String}
 */
const operationToDisplayFormattedString = (operation) =>
  `Target: ${operationToTarget(operation)}
Operator: ${operationToOperator(operation)}
Value: ${operationToValue(operation)}`;

/**
 * @function payRuleToPayRuleDisplay
 * @param {PayRule} payRule
 * @returns {PayRuleDisplay}
 */
export const payRuleToPayRuleDisplay = (
  { name, conditions, adjustments, startDate } = new PayRule()
) =>
  new PayRuleDisplay(
    name,
    conditions.map(operationToDisplayFormattedString).join(','),
    adjustments.map(operationToDisplayFormattedString).join(','),
    startDate
  );

/**
 * @function userPayPeriodDataRowToPayRecordDisplay
 * @param {UserPayPeriodDataRow} userPayPeriodDataRow
 * @returns {PayRecordDisplay}
 */
export const userPayPeriodDataRowToPayRecordDisplay = (
  {
    employeeId = '',
    startDate = '',
    endDate = '',
    payModelName = '',
    totalCalls = '',
    sales = '',
    totalPay = '',
    serveMetricName = '',
    serveValue = '',
    servePay = '',
    sp100MetricName = '',
    sp100Value = '',
    sp100Pay = '',
    heroValue = '',
    heroPay = '',
  } = new UserPayPeriodDataRow()
) =>
  new PayRecordDisplay(
    employeeId,
    startDate,
    endDate,
    payModelName,
    totalCalls,
    sales,
    totalPay,
    serveMetricName,
    serveValue,
    servePay,
    heroValue,
    heroPay,
    sp100MetricName,
    sp100Value,
    sp100Pay
  );

export const stringToStringWithSurroundingSpaces = (str = '') => ` ${str} `;

export const stringToRegExpWithSpaces = (str = '') =>
  new RegExp(stringToStringWithSurroundingSpaces(str));

export const stringContainsRegExp = (str = '', regExp = new RegExp('')) =>
  regExp.test(str);

export const stringIncludesDiscreteSubstring = (str = '', substr = '') =>
  stringContainsRegExp(str, stringToRegExpWithSpaces(substr));

export const metricNameAndPayRuleDetailToIsRelevantPayRuleDetail =
  (metricName = '') =>
    (payRuleDetail = new PayRuleDetail()) =>
      stringIncludesDiscreteSubstring(
        stringToLowerCase(payRuleDetailToConditionFormula(payRuleDetail)),
        stringToLowerCase(metricName)
      );

export const payRuleDetailsAndMetricNameToPayRuleDetails = (
  payRuleDetails = [],
  metricName = ''
) =>
  payRuleDetails.filter(metricNameAndPayRuleDetailToIsRelevantPayRuleDetail(metricName));

export const payMetricDetailAndPayRuleDetailToPayRuleAndPayMetricDetail = (
  payMetricDetail = new PayMetricDetail()
) => (
  payRuleDetail = new PayRuleDetail()
) =>
    new PayRuleAndPayMetricDetail(
      payRuleDetailToPayRuleName(payRuleDetail),
      payRuleDetailToConditionFormula(payRuleDetail),
      payRuleDetailToPayFormula(payRuleDetail),
      payMetricDetailToMetricName(payMetricDetail),
      payMetricDetailToMetricValue(payMetricDetail),
      payMetricDetailToStartDate(payMetricDetail),
      payMetricDetailToEndDate(payMetricDetail)
    );

export const payRuleDetailsAndPayMetricDetailToPayRuleAndPayMetricDetails =
  (payRuleDetails = []) =>
    (payMetricDetail = new PayMetricDetail()) => {
      const relevantPayRuleDetails = payRuleDetailsAndMetricNameToPayRuleDetails(
        payRuleDetails,
        payMetricDetailToMetricName(payMetricDetail)
      );
      if (relevantPayRuleDetails.length) return relevantPayRuleDetails
        .map(
          payMetricDetailAndPayRuleDetailToPayRuleAndPayMetricDetail(
            payMetricDetail
          )
        );
      return [payMetricDetailAndPayRuleDetailToPayRuleAndPayMetricDetail(payMetricDetail)];
    };

export const expertIdAndPayRuleAndPayMetricDetailToPayRecordDetailDisplay =
  (expertId = '') =>
    ({
      startDate = '',
      endDate = '',
      payRuleName = '',
      conditionFormula = '',
      payFormula = '',
      metricName = '',
      metricValue = '',
    }) =>
      new PayRecordDetailDisplay(
        expertId,
        startDate,
        endDate,
        payRuleName,
        conditionFormula,
        payFormula,
        metricName,
        metricValue
      );

/**
 * @function userPayPeriodDataRowToPayRecordDetailDisplays
 * @param {UserPayPeriodDataRow} userPayPeriodDataRow
 * @returns {PayRecordDetailDisplay[]}
 */
export const userPayPeriodDataRowToPayRecordDetailDisplays = ({
  employeeId = '',
  payRuleDetails = [],
  payMetricDetails = [],
}) =>
  payMetricDetails
    .flatMap(payRuleDetailsAndPayMetricDetailToPayRuleAndPayMetricDetails(payRuleDetails))
    .map(expertIdAndPayRuleAndPayMetricDetailToPayRecordDetailDisplay(employeeId));

const uiEventCurrentTargetToValue = ({ currentTarget: { value } }) => value;

const dropDownEventToValue = (event) => uiEventCurrentTargetToValue(event);

const datePickerEventToValue = (event) => event;

const textInputEventToValue = ({ target: { value } }) => value;

export const dispatchToDatePickerHandler = (dispatch) => (event) =>
  dispatch(dateToQueryControlsDateValueChangeAction(datePickerEventToValue(event)));

export const dispatchToDisplayViewPickerHandler = (dispatch) => (event) =>
  dispatch(stringToQueryControlsDropdownValueChangeAction(dropDownEventToValue(event)));

// eslint-disable-next-line max-len
export const dispatchAndSelectedDisplayTypeAndSelectedDateAndSelectedEmployeeIdAndBooleanToQueryHandler =

  (dispatch, selectedDisplayType, selectedDate, selectedEmployeeId, hasAnyQueryRun) =>
    async () => {
      if (hasAnyQueryRun === false) dispatch(booleanToHasAnyQueryRunChangeAction(true));

      dispatch(booleanToIsDataLoadingChangeAction(true));

      if (
        stringEqualityDecisionCaseInsensitive(
          selectedDisplayType,
          ADMIN_VIEW_METRIC_DEFINITIONS_QUERY_TYPE
        )
      ) {
        const response = await appSyncQueryPromise(
          stringAndDateAndStringToAppSyncQuery(selectedDisplayType, selectedDate)
        );
        dispatch(emptyAllDisplayValuesAction());
        dispatch(
          metricDefinitionDisplaysToMetricDefinitionDisplaysChangeAction(
            graphqlResponseToMetricDefinitions(response).map(
              metricDefinitionToMetricDefinitionDisplay
            )
          )
        );
      }

      if (
        stringEqualityDecisionCaseInsensitive(
          selectedDisplayType,
          ADMIN_VIEW_PAYRULES_QUERY_TYPE
        )
      ) {
        const response = await appSyncQueryPromise(
          stringAndDateAndStringToAppSyncQuery(selectedDisplayType, selectedDate)
        );
        dispatch(emptyAllDisplayValuesAction());
        dispatch(
          payRuleDisplaysToPayRuleDisplaysChangeAction(
            graphqlResponseToPayRules(response).map(payRuleToPayRuleDisplay)
          )
        );
      }

      if (
        stringEqualityDecisionCaseInsensitive(
          selectedDisplayType,
          ADMIN_VIEW_PAY_RECORDS_QUERY_TYPE
        )
      ) {
        const response = await appSyncQueryPromise(
          stringAndDateAndStringToAppSyncQuery(selectedDisplayType, selectedDate)
        );
        dispatch(emptyAllDisplayValuesAction());
        dispatch(
          payRecordDisplaysToPayRecordDisplaysChangeAction(
            graphqlResponseToUserPayPeriodDataRows(response).map(
              userPayPeriodDataRowToPayRecordDisplay
            )
          )
        );
      }

      if (
        stringEqualityDecisionCaseInsensitive(
          selectedDisplayType,
          ADMIN_VIEW_EMPLOYEE_PAY_RECORDS_QUERY_TYPE
        )
      ) {
        const response = await appSyncQueryPromise(
          stringAndDateAndStringToAppSyncQuery(
            selectedDisplayType,
            selectedDate,
            selectedEmployeeId
          )
        );
        dispatch(emptyAllDisplayValuesAction());
        dispatch(
          payRecordDetailDisplaysToPayRecordDetailDisplaysChangeAction(
            graphqlResponseToUserPayPeriodDataRowsDetailed(response).flatMap(
              userPayPeriodDataRowToPayRecordDetailDisplays
            )
          )
        );
      }

      dispatch(booleanToIsDataLoadingChangeAction(false));
    };

/**
 * @function filterValuesAndMetricDefinitionDisplaysToMetricDefinitionDisplays
 * @param {String[]} filterValues
 * @param {MetricDefinitionDisplay[]} metricDefinitionDisplays
 */
const filterValuesAndMetricDefinitionDisplaysToMetricDefinitionDisplays = (
  filterValues = [],
  metricDefinitionDisplays = []
) => metricDefinitionDisplays.filter(filtersAndObjectToBoolean(filterValues));

/**
 * @function filterValuesAndPayRuleDisplaysToPayRuleDisplays
 * @param {String[]} filterValues
 * @param {PayRuleDisplay[]} payRuleDisplays
 */
const filterValuesAndPayRuleDisplaysToPayRuleDisplays = (
  filterValues = [],
  payRuleDisplays = []
) => payRuleDisplays.filter(filtersAndObjectToBoolean(filterValues));

/**
 * @function filterValuesAndPayRecordDisplaysToPayRecordDisplays
 * @param {String[]} filterValues
 * @param {PayRecordDisplay[]} payRecordDisplays
 */
const filterValuesAndPayRecordDisplaysToPayRecordDisplays = (
  filterValues = [],
  payRecordDisplays = []
) => payRecordDisplays.filter(filtersAndObjectToBoolean(filterValues));

/**
 * @function filterValuesAndPayRecordDetailDisplaysToPayRecordDetailDisplays
 * @param {String[]} filterValues
 * @param {PayRecordDetailDisplay[]} payRecordDetailDisplays
 */
const filterValuesAndPayRecordDetailDisplaysToPayRecordDetailDisplays = (
  filterValues = [],
  payRecordDetailDisplays = []
) => payRecordDetailDisplays.filter(filtersAndObjectToBoolean(filterValues));

/**
 * @function payRecordDetailDisplayToSortingNumber
 * PayRecordDetailDisplay -> Number
 * @param {PayRecordDetailDisplay} payRecordDetailDisplay
 * @returns {Number}
 */
const payRecordDetailDisplayToSortingNumber = (
  { payFormula, conditionFormula } = new PayRecordDetailDisplay()
) => (payFormula && conditionFormula ? -1 : 1);

/**
 * @function payRecordDetailDisplaysToSortedPayRecordDetailDisplays
 * @param {PayRecordDetailDisplay[]} payRecordDetailDisplays
 * @returns {PayRecordDetailDisplay[]}
 */
const payRecordDetailDisplaysToSortedPayRecordDetailDisplays = (
  payRecordDetailDisplays = []
) => payRecordDetailDisplays.sort(payRecordDetailDisplayToSortingNumber);

export const adminDataViewReduxStateToFilteredDisplaysAdminDataViewState = (
  {
    metricDefinitionDisplays,
    payRuleDisplays,
    payRecordDisplays,
    payRecordDetailDisplays,
    selectedDisplayView,
    selectedDate,
    isDataLoading,
    hasAnyQueryRun,
    filterValues,
    tableViewCount,
    selectedEmployeeId,
  } = new AdminDataViewReduxState()
) =>
  new AdminDataViewReduxState(
    filterValuesAndMetricDefinitionDisplaysToMetricDefinitionDisplays(
      filterValues,
      metricDefinitionDisplays
    ),
    filterValuesAndPayRuleDisplaysToPayRuleDisplays(filterValues, payRuleDisplays),
    filterValuesAndPayRecordDisplaysToPayRecordDisplays(filterValues, payRecordDisplays),
    payRecordDetailDisplaysToSortedPayRecordDetailDisplays(
      filterValuesAndPayRecordDetailDisplaysToPayRecordDetailDisplays(
        filterValues,
        payRecordDetailDisplays
      )
    ),
    selectedDisplayView || displayViewTypes[0],
    selectedDate,
    isDataLoading,
    hasAnyQueryRun,
    filterValues,
    tableViewCount || TABLE_VIEW_COUNT_OPTIONS[0],
    selectedEmployeeId
  );

export const dispatchToFilterHandler = (dispatch) => (event) =>
  dispatch(stringsToQueryControlsFilterValuesChangeAction(event));

export const dispatchToTableViewCountHandler = (dispatch) => (event) =>
  dispatch(stringToViewControlsTableViewCountChangeAction(dropDownEventToValue(event)));

export const dispatchToSelectedEmployeeIdHandler = (dispatch) => (event) =>
  dispatch(stringToSelectedEmployeeIdChangeAction(textInputEventToValue(event)));
