import { useState } from 'react';

import {
  valuesToLocalStorageItemValue,
  keyToSetLocalStorageValue,
} from '../../transformations/core';

export const useLocalStorage = (key, initialValue) => {
  const [storedValue] = useState(() =>
    valuesToLocalStorageItemValue(key, initialValue, Date.now(), localStorage));
  return [storedValue, keyToSetLocalStorageValue(key)];
};

export default useLocalStorage;
