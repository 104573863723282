import React from 'react';
import helixLogoWhite from '../../data/images/helix-logo-white.svg';

export const HelixLogoAppNameAndBuildVersionComponent = ({ appName, buildVersion }) => (
  <div className="helix-app-name-and-build-version">
    <div className="helix-app-name">
      <img src={helixLogoWhite} className="helix-logo" alt="helix logo" />
      <div className="app-name">{appName}</div>
    </div>
  </div>
);
