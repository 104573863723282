/* eslint-disable indent */
import React from 'react';
import {
  adminDataViewReduxStateToSelectedDisplayView,
  adminDataViewReduxStateToHasAnyQueryRun,
  adminDataViewReduxStateToSelectedEmployeeId,
  AdminPageComponent,
  adminDataViewReduxStateToSelectedDate,
  AdminDataViewConfig,
} from '@helix/admin-data-view-ui';
import { Alert, Text } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { useSelector, useDispatch } from 'react-redux';
import {
  dispatchToDatePickerHandler,
  dispatchToDisplayViewPickerHandler,
  dispatchAndSelectedDisplayTypeAndSelectedDateAndSelectedEmployeeIdAndBooleanToQueryHandler,
  dispatchToFilterHandler,
  dispatchToTableViewCountHandler,
  dispatchToSelectedEmployeeIdHandler,
  adminDataViewReduxStateToFilteredDisplaysAdminDataViewState,
} from './transformations';
import { displayViewTypes } from './constants';
import { reduxStateToAdminDataViewState } from '../../data/datatypes';
import { ADMIN_VIEW_METRIC_DEFINITIONS_QUERY_TYPE } from '../../io/appsync/constants';

export const NotAdminComponent = () => (
  <Alert
    icon={<IconAlertCircle size={16} />}
    title="Forbidden"
    color="red"
    variant="light"
  >
    <Text color="black" size="lg">
      <b>You do not have admin access.</b>
    </Text>
  </Alert>
);

export const AdminDataViewContainer = () => {
  const dispatch = useDispatch();

  const adminDataViewState = useSelector(reduxStateToAdminDataViewState);

  // default initial query for handler to metricDefinitions
  const selectedDisplayType =
    adminDataViewReduxStateToSelectedDisplayView(adminDataViewState) ||
    ADMIN_VIEW_METRIC_DEFINITIONS_QUERY_TYPE;

  const selectedDate = adminDataViewReduxStateToSelectedDate(adminDataViewState);

  // TODO: make sure this works
  const hasAnyQueryRun = adminDataViewReduxStateToHasAnyQueryRun(adminDataViewState);

  // TODO: make sure this works
  const selectedEmployeeId =
    adminDataViewReduxStateToSelectedEmployeeId(adminDataViewState);

  const adminDataViewConfig = new AdminDataViewConfig(
    adminDataViewReduxStateToFilteredDisplaysAdminDataViewState(adminDataViewState),
    displayViewTypes,
    dispatchToDatePickerHandler(dispatch),
    dispatchToDisplayViewPickerHandler(dispatch),
    // eslint-disable-next-line max-len
    dispatchAndSelectedDisplayTypeAndSelectedDateAndSelectedEmployeeIdAndBooleanToQueryHandler(
      dispatch,
      selectedDisplayType,
      selectedDate,
      selectedEmployeeId,
      hasAnyQueryRun
    ),
    dispatchToFilterHandler(dispatch),
    dispatchToTableViewCountHandler(dispatch),
    dispatchToSelectedEmployeeIdHandler(dispatch)
  );

  return <AdminPageComponent {...adminDataViewConfig} />;
};

export default AdminDataViewContainer;
