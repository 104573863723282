import React from 'react';
import './css/main.css';
import noPaySVG from './noPaySVG.svg';

export const PayRetiredContainer = () => {
  return (
    <div>
      <head>
        <link
          href="https://fonts.googleapis.com/css?family=Apercu+Pro&display=swap"
          rel="stylesheet"
        />
        <link href="./css/main.css" rel="stylesheet" />
        <title>Document</title>
      </head>
      <body>
        <div className="v25_69">
          <span className="v25_70">
            <br />
            <br />
            Pardon our dust. Helix Pay has temporarily moved to a new PowerBi while we
            make some exciting enhancements!
            <br />
            <br />
            COL experts - please use the new URL at:
            <a href="https://rebrand.ly/OneStopPayShop">
              https://rebrand.ly/OneStopPayShop
            </a>
            <br />
            <br />
            US, PH, and CAN experts - please use the new URL at:
            <a href="https://rebrand.ly/HourlyExpertICP">
              https://rebrand.ly/HourlyExpertICP
            </a>
            <br />
            <br />
            We will send additional communication in the future once the Helix Pay
            enhancements are complete.
          </span>
          <span className="v25_71">We are Remodeling Helix Pay</span>
        </div>
        <img src={noPaySVG} className="v25_72" alt="helix logo" />
      </body>
    </div>
  );
};

export default PayRetiredContainer;
