import React from 'react';
import { Setting } from '../../data/datatypes';
import { DARK_THEME_NAME, DEFAULT_THEME_NAME, THEME_SETTING_NAME } from '../../transformations/core';

// String -> Setting
const themeValueToNewThemeSetting = (value = '') => {
  if (value === DARK_THEME_NAME) return new Setting(THEME_SETTING_NAME, DEFAULT_THEME_NAME);
  return new Setting(THEME_SETTING_NAME, DARK_THEME_NAME);
};

export const ThemeSettingComponent = ({ theme: { value = '' } = new Setting(), changeHandler }) => {
  const onMouseDown = () => changeHandler(themeValueToNewThemeSetting(value));
  return (
    <div className="element-event-handler">
      <div className="text-and-toggle">
        <div className="">Dark Mode</div>
        <div
          onMouseDown={onMouseDown}
          className="toggle"
          style={value === 'dark' ? { paddingLeft: '17px' } : {}}
        />
      </div>
    </div>
  );
};
