import { useLocalStorage } from '../UseLocalStorage';
import { Core, ReduxState } from '../../data/datatypes';
import {
  HELIXUSER_KEY,
  AUTHENTICATED_KEY,
  INITIAL_AUTHENTICATED_VALUE,
  INITIAL_HELIXUSER_VALUE,
} from '../../data/constants';

export const useStateHydration = () => {
  const [authenticated] = useLocalStorage(AUTHENTICATED_KEY, INITIAL_AUTHENTICATED_VALUE);
  const [helixUser] = useLocalStorage(HELIXUSER_KEY, INITIAL_HELIXUSER_VALUE);
  return new ReduxState(new Core(authenticated, helixUser));
};

export default useStateHydration;
