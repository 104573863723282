import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { arrayToHasLength } from '@helix/react-lib';
import { HeaderComponent } from './components/HeaderComponent';
import {
  stateToEmployeeId,
  stateToSettings,
  settingsToContainerClassName,
  packageJsonToBuildVersion,
  stateToShowSettings,
  stateToStateWithoutPrivateData,
} from './transformations/core';
import { currentSettingsAndSettingToReduxAction } from './reducers/EmployeeDataReducer';
import { employeeDataObservableAppSync } from './io/events';
import packageJson from '../package.json';
import {
  HelixAppNameAndBuildVersion,
  LogItem,
  Setting,
  SettingsAndHandlers,
  HelixAPICall,
  PostBody,
} from './data/datatypes';
import { API_NAME, POST, DEFAULT_HEADERS, LOG_API_PATH } from './data/constants';
import { Routes } from './Routes';
import { helixAPICallToPromise } from './io/api';
import { appSyncQueryPromise } from './io/appsync';
import { mutationTypeToAppSyncMutation } from './transformations/graphql';
import { booleanToShowSettingsAction } from './reducers/CoreReducer';

export const DEFAULT_CURRENT_APP = 'Pay';

const employeeIdToEmployeeDataSideEffect = (employeeId) => () => {
  employeeDataObservableAppSync.emitData('employeeData', { employeeId });
};

export const stateLoggingSideEffect = (state) => () => {
  helixAPICallToPromise(
    new HelixAPICall(
      API_NAME,
      LOG_API_PATH,
      POST,
      new PostBody(
        new LogItem(stateToStateWithoutPrivateData(state), new Date().toISOString()),
        DEFAULT_HEADERS
      )
    )
  );
};

export const settingsChangeSideEffect = (employeeId, settings) => () => {
  if (Array.isArray(settings) && arrayToHasLength(settings))
    appSyncQueryPromise(
      mutationTypeToAppSyncMutation('settings', {
        employeeId,
        settings: JSON.stringify(settings),
      })
    );
};

export const subscriptionToUnsubscribe =
  (subscription = {}) =>
  () =>
    subscription.unsubscribe();

export const App = () => {
  const dispatch = useDispatch();
  const employeeId = useSelector(stateToEmployeeId);
  const settings = useSelector(stateToSettings);
  const showSettings = useSelector(stateToShowSettings);
  const containerClassName = settingsToContainerClassName(settings);

  const stringToFirstLetterUpperCaseString = (string = '') =>
    `${string[0].toUpperCase()}${string.slice(1)}`;
  const pathnameToAppName = () =>
    stringToFirstLetterUpperCaseString(window.location.pathname.slice(1) || 'pay');

  useEffect(employeeIdToEmployeeDataSideEffect(employeeId), []);
  useEffect(settingsChangeSideEffect(employeeId, settings), [settings]);
  // useEffect(
  //   stateLoggingSideEffect(
  //     reduxState
  //   ),
  //   [settings]
  // );

  const appNameAndBuildVersion = new HelixAppNameAndBuildVersion(
    pathnameToAppName(),
    packageJsonToBuildVersion(packageJson)
  );

  const settingChangeHandler = (change = new Setting()) => {
    dispatch(currentSettingsAndSettingToReduxAction(settings, change));
  };
  const settingIconClickHandler = () => dispatch(booleanToShowSettingsAction(true));
  const settingsBlurHandler = () => dispatch(booleanToShowSettingsAction(false));

  const settingsAndHandlers = new SettingsAndHandlers(
    settings,
    settingChangeHandler,
    settingIconClickHandler,
    settingsBlurHandler,
    showSettings
  );

  return (
    <div className={containerClassName}>
      <HeaderComponent
        appNameAndBuildVersion={appNameAndBuildVersion}
        settingsAndHandlers={settingsAndHandlers}
      />
      <Routes />
    </div>
  );
};

export default App;
