import { Headers, HelixUser } from './datatypes';

export const API_NAME = 'HelixCoreAPI';
export const POST = 'POST';
export const DEFAULT_CONTENT_TYPE = 'application/json';
export const DEFAULT_HEADERS = new Headers(DEFAULT_CONTENT_TYPE);
export const GET = 'GET';
export const LOG_API_PATH = '/logging';
export const DEFAULT_PROMISE_RESPONSE = {};
export const DEFAULT_PROMISE = Promise.resolve(DEFAULT_PROMISE_RESPONSE);
export const HELIXUSER_KEY = 'helixUser';
export const AUTHENTICATED_KEY = 'authenticated';
export const INITIAL_AUTHENTICATED_VALUE = false;
export const INITIAL_HELIXUSER_VALUE = new HelixUser();
export const NO_PAY_RECORD_PATH = '/pay-record-not-found';
