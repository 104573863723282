export class NotFound {
  constructor(content = {}, src = '') {
    this.content = content;
    this.src = src;
  }
}

export class Content {
  constructor(title = '', message = '', feedback = '') {
    this.title = title;
    this.message = message;
    this.feedback = feedback;
  }
}
